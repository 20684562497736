.main-area {
  padding: 10px 0 10px 10px;
  min-height: 600px;
}

.main-area > h2 {
  margin: 10px 10px 20px 0;
  font-size: 1.8em;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
