.panels {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.panels__panel {
  padding: 5px 8px;
  margin-right: 10px;
  border: solid 1px #fff;
  min-width: 300px;
  flex: 1 1 0px;
  margin-bottom: 5px;
  min-height: 300px;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.panels__panel__title {
  font-size: 2em;
  font-weight: bold;
  margin: 0;
  padding:0;
}

.panels__panel__buttons {
  padding: 5px 0;
  margin-top: auto;
  align-self: flex-end;
}
.panels__panel__buttons .button {
  margin-top: 5px;
  margin-left: 5px;
  float: right;
}

.panels__panel__error__icon {
  text-align: center;
  font-size: 90px;
}

.panels__panel__error__text {
  text-align: center;
  font-size: 1.1em;
}
