.edit-user__invited-user {
  font-size: 1.2em;
}

.edit-user__buttons {
  padding-right: 10px;
  display: flex;
  flex-grow: 1;
  margin: 20px 0;
}

.edit-user__buttons .button {
  margin-left: auto;
}

@media all and (max-width: 650px) {
  .edit-user__buttons .button {
    flex-grow: 1;
  }
}

