.form__status-message {
  margin-bottom: 10px;
  display: flex;
  padding: 10px;
  border: solid 3px #fff;
  margin-right: 10px;
}

.form__status-message.error {
  color: #FF852C;
  border-color: #FF852C;
}

.form__status-message__icon {
  font-size: 32px;
}

.form__status-message__text {
  padding-left: 10px;
  flex-grow: 1;
  font-size: 1.1em;
}
