.form__item__outer {
  margin: 0 0 10px 0;
  font-size: 1.4em;
}

.form__item {
  padding: 5px;
  display: flex;
}

.form__item__label {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.form__item__outer.error {
  background-color: #9E2420;
}

input.form-control {
  flex-grow: 1;
  padding: 5px;
  border: solid 1px #000;
  font-size: 1em;
}

input.form-control.error {
  border: solid 1px #f00;
}

.form-control-validation-message {
  font-size: 0.9em;
  padding: 5px;
  text-align: right;
  color: #fff;
}