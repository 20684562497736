.review-panel__queue-empty__icon {
  font-size: 120px;
  text-align: center;
  margin-top: 20px
}

.review-panel__queue-empty__text {
  font-size: 2em;
  text-align: center;
}

.reviews-panel__report-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reviews-panel__report-list__item {
  cursor: pointer;
  margin-top: 5px;
  padding: 5px 5px 10px 10px;
  border: 3px solid #eee;
  min-height: 60px;
}

.reviews-panel__report-list__item__icon {
  float: left;
  margin-right: 10px;
  font-size: 50px;
}

.reviews-panel__report-list__item__title {
  font-size: 2em;
}

.reviews-panel__report-list__item__details__author,
.reviews-panel__report-list__item__details__created {
  display: inline-block;
}

.reviews-panel__report-list__item__details__created {
  margin-right: 10px;
}
