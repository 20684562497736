.list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.list__item {
  cursor: pointer;
  margin-top: 5px;
  padding: 5px 5px 10px 10px;
  border: 3px solid #eee;
}

.list__item > a {
  display: block;
  text-decoration: none;
}

.list__item.with-icon {
  min-height: 60px;
}

.list__item__icon {
  float: left;
  margin-right: 10px;
  font-size: 50px;
}

.list__item__title {
  font-size: 2em;
}
