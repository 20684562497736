.view-report__sections {
  margin: 10px 10px 0 0;
}

.view-report__sections__tabs {
  min-width: 290px;
  padding: 0;
  margin: 0;
  list-style: none;
  float: left;
  border-bottom: 3px solid #fff;
}

.view-report__sections__tabs__tab {
  min-height: 60px;
  background-color: #333;
  display: flex;
  cursor: pointer;
  font-weight: bold;
  border: solid 3px #fff;
  border-right: 0;
  border-bottom: 0;
}

.view-report__sections__tabs__tab__text {
  align-self: center;
  font-size: 1.3em;
  margin-left: 10px;
  flex-grow: 1;
}

.view-report__sections__tabs__tab__icon {
  margin-left: auto;
  font-size: 32px;
}

.view-report__sections__tabs__tab__icon,
.view-report__sections__tabs__tab__status {
  align-self: center;
  margin: 0 10px 0 auto;
  width: 42px;
  text-align: center;
}

.view-report__sections__tabs__tab__status {
  margin-left: 10px;
  font-size: 22px;
}

.tab__selected {
  background-color: var(--color-blue);
}

.view-report__graph-container {
  background-color: #fff;
  margin-bottom: 20px;
}

.view-report__sections__content {
  border: solid 3px #fff;
  flex-grow: 1;
  padding: 10px;
  margin-left: 290px;
  min-height: 250px;
}

.view-report__sections__content h2 {
  margin: 0 0 20px 0;
}

.view-report__mobile-view .view-report__sections {
  min-height: auto;
  display: block;
}

.view-report__mobile-view .view-report__sections__tabs {
  padding: 0;
  float: none;
  border-right: 3px solid #fff;
  border-bottom: 3px solid #fff;
}

.view-report__mobile-view .tab__selected {
  margin-bottom: 0;
  border: 3px solid #fff;
}

.view-report__mobile-view .view-report__sections__content {
  padding: 10px 0 0 0;
  margin: 0;
  border: 0;
}

.view-report__mobile-view .view-report__graph-container {
  margin-right: 10px;
}

.discrepency__section {
  border: solid 3px #fff;
  margin: 0 0 10px 0;
  display: flex;
  padding: 10px;
}

.discrepency__section__icon {
  font-size: 32px;
  margin-right: 12px;
}

.discrepency__section__text {
  font-size: 1.2em;
  padding-top: 2px;
}

.view-report__mobile-view .discrepency__section {
  margin-right: 10px;
}
