.report-processing {
  position: relative;
  margin: 50px 0 0 0;
  text-align: center;
  font-size: 1.5em;
  padding: 0 20px;
  margin-right: 10px;
}

.report-processing__icon {
  padding-top: 18px;
  font-size: 72px;
  margin: 0 0 45px 0;
}

.report-processing__spinner__container {
  position: absolute;
  top: -54px;
  left: 0;
  right: 0;
}

.report-processing__spinner {
  align-items: center;
  font-size: 170px;
  color: var(--color-blue);
  animation: rotation 2s infinite linear;
  margin: 0 auto;
  width: 250px;
  height: 250px;
  display: flex;
  justify-content: center;
}