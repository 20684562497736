.profile-container__item {
  max-width: 500px;
}

.user-details__form {
  margin-bottom: 10px;
  font-size: 1.2em;
  clear: both;
}

.user-details__form input {
  font-size: 1em;
  margin: 5px 0;
}

.user-details__button {
  display: flex;
}

.user-details__button button {
  margin-left: auto;
}

.user-details__container {
  clear: both;
  margin-right: 10px;
}

.user-details__password-policy {
  margin: 0;
}

.user-details__password-policy__checklist {
  list-style: none;
  padding-left: 10px;
  margin-top: 4px;
}

.user-details__password-policy__checklist__item.completed {
  color: #13D61D;
}

.user-details__password-policy__checklist__item.warning {
  color: #FF8803;
}

.user-details__password-policy__checklist__item__icon {
  display: inline-block;
  width: 20px;
  margin-right: 5px;
}

.user-details__password-policy__checklist__item__text {
  display: inline-block;
}

.user-details__status-message {
  margin-bottom: 10px;
}

.user-details__status-message__icon {
  font-size: 32px;
  float: left;
}

.user-details__status-message__text {
  padding-left: 50px;
}
