.usage-graph__outer {
  border: 3px solid #fff;
  height: 40px;
  border-radius: 10px;
  user-select: none;
}

.usage-graph__inner {
  background-color: var(--color-blue);
  width: 0;
  height: 100%;
  border-radius: 7px 0 0 7px;
}
