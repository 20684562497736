.filter {
}

.filter__item {
  display: inline-block;
  font-size: 1.4em;
  margin-right: 10px;
  white-space: nowrap;
  margin-bottom: 15px;
}

.filter__item select,
.filter__item input {
  font-size: 1em;
  margin-left: 10px;
}
