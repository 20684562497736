.view-report__summary {
  margin-right: 10px;
  padding: 10px;
  display: flex;
  border: solid 3px #fff;
}

.view-report__summary__section {
  flex-grow: 1;
  width: 33%;
}

.view-report__summary__section__item {
  display: flex;
  margin-top: 5px;
  font-size: 1.2em;
}

.view-report__summary__section__item__icon {
  margin-right: 10px;
}

.view-report__mobile-view .view-report__summary {
  display: block;
}

.view-report__mobile-view .view-report__summary__section {
  width: auto;
}
