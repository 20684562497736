.reports__filter__item.filter__item__advanced-date {
  font-size: 1em;
}

.reports__filter__item {
  display: inline-block;
  font-size: 1.4em;
  margin-right: 10px;
  white-space: nowrap;
  margin-bottom: 15px;
  min-height: 35px;
}

.reports__filter__item select,
.reports__filter__item input {
  font-size: 1em;
  margin-left: 10px;
}

.filter__header {
  display: flex;
  margin-bottom: 10px;
}

.filter__header__button {
  margin: 0 10px 0 auto;
}

.tabs__panel {
  margin: 15px 10px 10px 0;
}

.reports__search__reference {
  width: 220px;
}

.reports__search__buttons {
  min-height: 50px;
}

.reports__search__buttons > .button {
  float: right;
}


@media all and (max-width: 650px) {
  .reports__search__reference {
    max-width: 170px;
  }

  .reports__filter__item {
    display: flex;
    margin-right: 0;
  }
  
  .reports__filter__item > select,
  .reports__filter__item > input {
    width: 100%;
    flex-grow: 1;
  }
}

