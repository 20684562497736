.descriptive-selector {
  list-style: none;
  margin: 10px 10px 0 0;
  padding: 0;
}

.descriptive-selector__item {
  padding: 10px;
  border: solid 3px #fff;
  margin-bottom: 10px;
}

.descriptive-selector__label {
  display: flex;
  flex-direction: row;
}

.descriptive-selector__label input {
  display: none;
}

.descriptive-selector__label__icon {
  font-size: 42px;
  padding: 0 10px;
  text-align: center;
  min-width: 60px;
}

.descriptive-selector__checkbox {
  font-size: 24px;
  width: 30px;
  text-align: center;
  padding-top: 11px;
}

.descriptive-selector__item-selected {
  background-color: var(--color-blue);
}

.descriptive-selector__label__title {
  font-size: 1.2em;
}
