.home__quick-functions {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
}

.home__quick-functions {
  display: flex;
  flex-direction: row-reverse;
  padding-right: 10px;
}

.home__quick-functions li {
  margin-left: 5px;
}

@media all and (max-width: 450px) {
  .home__quick-functions {
    display: block;
  }

  .home__quick-functions li {
    margin-left: 0;
    width: 100%;
  }

  .home__quick-functions .button {
    display: block;
    margin-bottom: 5px;
    margin-left: 0;
    width: 100%;
  }
}
