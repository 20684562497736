.dynamic-table {
  font-size: 1.4em;
  border-collapse: collapse;
  border-top: solid 1px #fff;
  border-bottom: solid 1px #fff;
}

.dynamic-table__row__header,
.dynamic-table__row__cell {
  border-bottom: solid 1px #fff;
}

.dynamic-table__row__header {
  text-align: right;
  padding: 5px 15px 5px 5px;
}

.dynamic-table__row__cell {
  padding: 5px 15px 5px 5px;
  text-align: right;
}
