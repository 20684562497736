.invitations-panel__none__icon {
  text-align: center;
  font-size: 90px;
}

.invitations-panel__none__text {
  text-align: center;
  font-size: 2em;
}

.invitations__panel__list__item.list__item.with-icon {
  min-height: 50px;
}

.invitations__panel__list__item .list__item__icon {
  font-size: 40px;
}

.invitations__panel__list__item .list__item__title {
 font-size: 1.4em;
 margin-left: 50px;
 white-space: nowrap;
 padding-right: 10px;
 white-space: nowrap;
 overflow: hidden;
 text-overflow: ellipsis;
}

.invitation-expired {
  border-color: #FF852C;
}

.invitation-expired .list__item__icon {
  color: #FF852C;
}
