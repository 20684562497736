.tabs__panel__tab-strip {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.tabs__panel__tab-strip__pre {
  border-bottom: solid 3px #fff;
  width: 10px;
}

.tabs__panel__tab-strip__post {
  border-bottom: solid 3px #fff;
  flex-grow: 1;
}

.tabs__panel__tab-strip__item {
  vertical-align: top;
  display: flex;
  font-size: 1.3em;
  border: 0;
  padding: 12px 18px 12px 18px;
  cursor: pointer;
  border-bottom: solid 3px #fff;
}

.tabs__panel__tab-strip__item.selected-tab {
  border: solid 3px #fff;
  border-bottom: 0;
  padding: 9px 15px 8px 15px;
}

.tabs__panel__tab-strip__item__icon {
  margin-right: 5px;
}

.tabs__panel__content {
  padding: 10px;
  border: solid 3px #fff;
  border-top: 0;
}
