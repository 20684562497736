.first-time__container {
  width: 600px;
  margin: 0 auto;
}

.first-time__icon {
  font-size: 100px;
  text-align: center;
  width: 140px;
  height: 140px;
  padding: 10px;
  margin: 10px auto 0 auto;
  border: 10px solid #fff;
  border-radius: 110px;
}

.first-time__text {
  font-size: 1.3em;
}

.first-time__form {
  padding-right: 8px;
}

.first-time__form .form__item > label {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.first-time__buttons {
  margin: 30px 8px 20px 0;
  display: flex;
}

.first-time__buttons button {
  margin-left: auto;
}

@media all and (max-width: 650px) {
  .first-time__container {
    padding: 5px;
    margin: 0;
    width: auto;
  }
}

