.foundations__container {
  display: flex;
  flex-direction: row-reverse;
  flex-flow: row wrap;
}

.foundations__table-container {
  margin-right: 30px;
  margin-bottom: 20px;
}

.foundations__matrix-table {
  font-size: 1.3em;
}

.foundations__matrix-table__cell {
  padding: 5px 15px;
  min-width: 35px;
  text-align: center;
}

.foundations__graph-container {
  background-color: #fff;
  margin-bottom: 20px;
  height: 500px;
}

.view-report__mobile-view .foundations__graph-container {
  margin: 0 20px 80px 10px;
}

.foundations__graph-container-inner {
  position: relative;
}

.foundations__graph-save {
  position: absolute;
  right: 5px;
  top: 5px;
}

.view-report__mobile-view .foundations__graph-save {
  bottom: -65px;
  right: 0;
  top: inherit;
}
