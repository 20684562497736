.dialog-modal {
  color: #000;
}

.dialog-modal__title {
  margin-top: 0;
}

.dialog-modal__buttons {
  text-align: right;
}

.dialog-modal__button {
  margin-left: 9px;
}

.dialog-modal .button {
  color: #fff;
  margin-left: 10px;
}

.dialog-modal__content {
  margin-bottom: 15px;
}

.dialog-modal-close {
  margin-top: 7px;
}

.dialog-modal__error {
  border: 3px solid #f00;
  padding: 5px;
  display: flex;
  margin-bottom: 10px;
}

.dialog-modal__error__icon {
  font-size: 32px;
  color: #f00;
}

.dialog-modal__error__text {
  font-size: 1.2em;
  margin: 0 10px;
}
