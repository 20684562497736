.invite-user__wrapper {
  max-width: 500px;
  margin: 0 auto;
}

.invite-user__description,
.invite-user__buttons {
  padding: 0 5px;
}

.invite-user__buttons {
  display: flex;
  margin-top: 20px;
}

.invite-user__buttons .button {
  margin-left: auto;
}

.invite-user__description__icon {
  font-size: 48px;
  float: left;
  margin: 0 10px 10px 0;
}

@media all and (max-width: 550px) {
  .invite-user__description,
  .invite-user__buttons {
    margin-right: 5px;
  }
  
  .invite-user__form {
    margin-left: 5px;
    margin-right: 10px;
  }

  .invite-user__buttons .button {
    width: 100%;
  }
}

