.error-page__icon {
  font-size: 120px;
  text-align: center;
  margin-right: 10px;
}

.error-page__container {
  margin: 0 auto;
  width: 600px;
}

@media all and (max-width: 650px) {
  .error-page__container {
    padding: 5px;
    margin: 0;
    width: auto;
  }
}
