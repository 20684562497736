.usage__list__container {
  margin-top: 10px;
}

.weekly-usage__container {
  min-height: 300px;
  display: flex;
  padding: 10px 5px 10px 10px;
  border: solid 3px #fff;
  margin-right: 10px;
}

.weekly-usage__month {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #fff;
  margin-right: 5px;
}

.weekly-usage__month-last {
  border-right: 0;
  margin-right: 0;
}

.weekly-usage__month__weeks {
  display: flex;
  flex-grow: 1;
}

.weekly-usage__month__label {
  height: 40px;
  text-align: center;
  font-weight: bold;
}

.weekly-usage__week {
  flex-grow: 1;
  margin-right: 5px;
  flex-direction: column;
  display: flex;
  margin-top: auto;
}

.weekly-usage__week__segment,
.weekly-usage__week__spacer {
  margin-top: auto;
}

.weekly-usage__week__segment {
}

.weekly-usage__week__spacer {
  border: solid 1px #666;
  background-color: #666;
}
