.user-details {
  margin: 15px 0;
}

.user-details__user-info-item {
  font-size: 1.2em;
}

.user-details__user-info-item__name {
  font-size: 2em;
  margin-bottom: 10px;
}

.user-details__user-info-item__user-role {
  font-size: 1.6em;
}
