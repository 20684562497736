.toast-bar {
  padding: 10px;
  font-size: 1.4em;
  background-color: var(--color-blue);
  display: flex;
  position: fixed;
  top: 52px;
  left: 0;
  right: 0;
  z-index: 2000;
}

.toast-bar__message {
  flex-grow: 1;
}

.toast-bar__close {
  margin-left: auto;
  cursor: pointer;
}
