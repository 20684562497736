.top-bar__menu__user {
  outline: none;
  position: absolute;
  top: 15px;
  left: 15px;
}

.top-bar__menu__user__icon,
.top-bar__menu__user__details {
  display: inline-block;
  outline: none;
  vertical-align: top;
}

.top-bar__menu__user__icon {
  width: 50px;
  height: 48px;
  border-radius: 25px;
  background-color: #333;
  text-align: center;
  font-size: 32px;
  padding: 1px 0;
  color: var(--color-blue);
}

.top-bar__menu__user__details {
  padding: 0 5px 8px 10px;
}

.top-bar__menu__user__details__user {
  font-size: 0.9em;
}

.top-bar__menu__user__details__team {
  font-size: 0.8em;
  font-style: italic;
}

.top-bar__menu__user__details__options {
  font-size: 0.8em;
}

.top-bar__menu__user__details__options a {
  text-decoration: none;
}

.top-bar__menu__user__details__options__signout {
  color: #fff;
  cursor: pointer;
}

.top-bar__menu__list {
  list-style: none;
  padding: 0;
  margin: 0;
  outline: none;
}

.top-bar__menu__item {
  margin-bottom: 14px;
  font-size: 1.2em;
}

.top-bar__menu a,
.top-bar__menu a:visited,
.top-bar__menu a:active {
  color: #fff;
}

.top-bar__menu__item__icon {
  display: inline-block;
  width: 30px;
  margin-right: 8px;
  cursor: pointer;
  color: #fff;
  text-align: center;
}

.top-bar__menu__item__text {
  display: inline-block;
  color: #fff;
  cursor: pointer;
}

/* Position and sizing of burger button */
.bm-burger-button {
  float: left;
  position: relative;
  width: 24px;
  height: 22px;
  margin: 6px 14px 0 0;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #000;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: var(--color-blue);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  top: -5px;
}
