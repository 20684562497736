.team-members__panel__list__item .list__item__icon {
  font-size: 40px;
  text-align: center;
  width: 50px;
}

.team-members__panel__list__item__email {
  margin-left: 60px;
  white-space: nowrap;
  padding-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
