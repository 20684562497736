.plan-summary__section {
  display: flex;
}

.plan-summary__section__icon {
  font-size: 40px;
  margin: 0 12px 0 5px;
  width: 50px;
  min-width: 50px;
  text-align: center;
}

.plan-summary__users__text {
  display: inline-block;
  font-size: 2em;
}

.plan-summary__section__graph,
.plan-summary__section__detail {
  flex-grow: 1;
  margin: 5px 0;
}

.plan-summary__section__text {
  font-size: 2em;
}